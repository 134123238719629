<template>
  <div class="bi-container">
    <div class="bi-content">
      <div class="left">
        <div class="material-box" v-if="materialInfo">
          <div class="m-title">{{ materialInfo.materialName }}</div>
          <div class="material-info">
            <div class="mi">
              <div class="type-item">{{ materialInfo.standardName }}</div>
                <img class="img" :src="materialInfo.materialCoverUrl" alt="">
            </div>
            <div class="right-content">
              <div class="one">共 {{ materialInfo.lessonList.length || 0 }} 个课时/共 {{ summary.totalCount || 0 }} 道题</div>
              <div class="two">
                <span>未打标签：</span>
                <span class="orange mar10">{{ summary.unLabelCount || 0 }}</span>
                <span>未审核：</span>
                <span class="blue">{{ summary.unReviewCount || 0 }}</span>
                <div>
                  未画坐标数量：<span class="orange">{{ summary.unCoordinateCount || 0 }}</span>
                </div>
              </div>
              <div class="three" @click="handChange('change')">
                <div>
                  <img src="~@/assets/images/changeMaterial.png" alt="">
                </div>
                <div>切换教辅</div>
              </div>
            </div>
          </div>
        </div>

        <div class="selectMaterialBox" v-else>
          <div class="selectMaterial" @click="handChange('select')">
            <div>
              <img src="~@/assets/images/changeMaterial.png" alt="">
            </div>
            <div>选择教辅</div>
          </div>
        </div>

        <div class="title">
          <div>章节和课时</div>

          <div class="subtitle">
            <span style="color: #FA6400;">“橙色”</span>表示未打标签数量，<span style="color: #1573BA">“蓝色”</span>表示未审核数量，<span style="color: #18C2C2;">“绿色”</span>表示总题数
          </div>
        </div>
        <div class="treeBox">
          <el-tree
            ref="tree"
            v-loading="loadtree"
            element-loading-text="数据加载中"
            :data="lessonTreeData"
            :default-expanded-keys="defaultExpand"
            :props="defaultProps"
            @node-click="handleLessonClick"
            highlight-current
            node-key="key"
          >
            <div class="treeItem" slot-scope="{ node, data }">
              <div class="label">{{ node.label }}</div>
              <div class="num" v-if="data.totalCount">
                <span title="未打标签数量">{{ data.unLabelCount }}</span>
                <span title="未审核数量">{{ data.unReviewCount }}</span>
                <span title="总题数">{{ data.totalCount }}</span>
              </div>
            </div>
            <!--<div class="el-row&#45;&#45;flex" slot-scope="{ node, data }">-->
            <!--  <div class="">{{ node.label }}</div>-->
            <!--  <span> - -->
            <!--    {{ data.unLabelCount }} {{ data.unReviewCount }}-->
            <!--  </span>-->
            <!--</div>-->
          </el-tree>
        </div>
      </div>
      <div class="right" v-loading.fullscreen.lock="fullscreenLoading">
        <question-bank
          v-if="questionList && questionList.length > 0"
          @getUploadProgress="getUploadProgress"
          @update="updateEditionInfo"
          :list="questionList"
          :pageNumber="pageNumber"
          :hasChildMaterialFlag="childMaterialFlag"
        />
        <upload-file size="small" v-else @getUploadProgress="getUploadProgress" :disabled="disabled" :uploadtip="uploadtip" :sqtip="sqtip" />
      </div>
    </div>

    <template>
      <change-material ref="materialComponents" @change="getData" />
    </template>
  </div>
</template>

<script>
import {
  getSubjectList,
  getStandardTeachingMaterial,
  getTeachingMaterialChapter,
  getSubjectAndGrade,
  getQuestionListByClassHourId,
  getTeachingMaterialStatistics
} from '@/api/branchImport.js'
import { materialRecordInfo, standardMaterialList } from '@/api/changeMaterial.js'
import uploadFile from './modules/uploadFile.vue'
import questionBank from './modules/questionBank.vue'
import { Subject } from '@/config/enums/Subject'
import changeMaterial from './modules/changeMaterial.vue'

import {
  commonSubsectionList
} from '@/api/question'

export default {
  components: {
    uploadFile,
    questionBank,
    changeMaterial
  },
  data () {
    return {
      searchData: {
        subjectId: undefined,
        editionGradeValue: undefined,
        // CLASSHOURID
        classHourId: undefined,
      },

      fullscreenLoading: false,
      subjectList: [],
      subjectValue: '',
      editionGradeList: [],
      editionGradeValue: [],
      treeData: [],
      defaultExpand: [], // 默认展开的节点的数组
      loadtree: false,
      cascaderKey: 1,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      formatProps: {
        value: 'id',
        label: 'name',
        children: 'phases'
      },
      questionList: [],
      disabled: true,
      uploadtip: '选择课时后即可导入',
      sqtip: '选择课时后即可识别',

      summary: {}, // 教材统计信息
      childMaterialFlag: false, // 是否有修订版，源前版本题目不能修改
      materialInfo: null,
      subsectionList: [],
      editionList: [],
      pageNumber: 1
    }
  },
  computed: {
    lessonTreeData () {
      // - 夹卷类型: 章节课时需作筛选
      const getLessonTree = (list, target = []) => {
        list.forEach(m => {
          if (m.type === 'LESSON') {
            target.push(m)
          } else if (m?.children?.length > 0) {
            getLessonTree(m.children, target)
          }
        })
        return target
      }
      // NOTE: 大后台添加多层级课时，录题系统“章节课时”接口获取数据层级异常。
      // 若出现多级课时，直接提示异常
      const isStandardPaper = this.searchData.materialType === 'CARRYING_PAPER'
      return isStandardPaper
        ? getLessonTree(this.treeData)
        : this.treeData
    }
  },
  activated () {
    console.log('--- activated() start')
    const searchData = this.$store.state.branchImport.searchData
    if (searchData) {
      this.searchData = searchData
      if (this.materialInfo) {
        this.getQuestionListByClassHourId(searchData.classHourId, this.materialInfo.materialId)
        this.updateEditionInfo()
      }
    }
    console.log('--- activated() ', `课时: ${searchData?.classHourId || '无缓存课时'}`)
  },
  mounted () {
    console.log('--- mounted() start')
    const data = this.$store.state.branchImport.searchData
    if (data) {
      this.searchData = data
    }
    this.getMaterialRecordInfo()
    console.log('--- mounted() ', `课时: ${data?.classHourId || '无缓存课时'}`)
  },
  methods: {
    // 切换教辅
    handChange (e) {
      if (e === 'change') {
        this.$refs.materialComponents.show(this.materialInfo)
      } else {
        this.$refs.materialComponents.show()
      }
    },

    getMaterialRecordInfo () {
      this.fullscreenLoading = true
      return materialRecordInfo().then(res => {
        if (res.code === 0) {
          this.childMaterialFlag = res.data.childMaterialFlag
          this.materialInfo = res.data
          if (this.materialInfo) {
            this.updateEditionInfo()
            this.getCommonSubsectionList()
          } else {
            this.$refs.materialComponents.show()
          }
        } else {
          this.$message.error(res.msg)
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.fullscreenLoading = false

        console.log('--- mounted() => getMaterialRecordInfo()')
      })
    },

    getData (e) {
      console.log('---- getData', e)
      const subjectInfo = Subject.find(m => m.value === e.subjectName)
      this.searchData.editionGradeValue = e.editionGradeValue
      this.searchData.editionName = e.editionName
      this.searchData.subjectId = e.subjectId
      this.searchData.subjectName = e.subjectName
      this.searchData.subjectValue = subjectInfo ? subjectInfo.id : undefined
      this.searchData.teachingMaterialId = e.teachingMaterialId
      this.searchData.materialType = e.materialType
      this.getMaterialRecordInfo()
    },

    // 更新教材信息
    updateEditionInfo () {
      return this.loadChapterOptions(this.materialInfo.standardMaterialId, this.materialInfo.materialId)
    },

    // 根据标准教材id 获取 年级、科目、教辅ID
    getSubjectAndGrade (standardTeachingMaterialId) {
      return getSubjectAndGrade(standardTeachingMaterialId).then(res => {
        if (res.code === 0) {
          if (!res.data) {
            throw new Error('未找到对应教辅')
          }
          this.searchData.teachingMaterialId = res.data.teachingMaterialId
          // TODO: materialType
          // this.searchData.materialType = res.data.xxx
        } else {
          throw new Error(res.msg)
        }
      })
    },
    loadStatistics (standardTeachingMaterialId, materialId) {
      // this.summary = null
      return getTeachingMaterialStatistics(standardTeachingMaterialId, materialId).then(res => {
        if (res.code === 0) {
          console.log(res.data)
          this.summary = res.data
        }
      })
    },

    // 获取 教辅-章节-课时 选项
    loadChapterOptions (standardTeachingMaterialId, materialId) {
      // 默认课时
      const classHourId = this.searchData.classHourId

      this.loadtree = true
      this.disabled = true
      this.defaultExpand = []
      this.loadStatistics(this.materialInfo.standardMaterialId, this.materialInfo.materialId)
      return getTeachingMaterialChapter(standardTeachingMaterialId, materialId).finally(() => {
        this.loadtree = false
        this.disabled = false
      }).then(res => {
        if (res.code === 0) {
          const treeData = res.data
          const curNode = this.formatTreeData(treeData, classHourId)
          this.treeData = treeData
          this.pageNumber = curNode && curNode.pageList ? curNode.pageList[0] : 1
          return curNode
        } else {
          throw new Error(res.msg)
        }
      }).then(curNode => {
        // 设置默认值 - 展开选中的节点
        if (curNode) {
          this.searchData.chapterId = curNode.parentChapterId
          this.searchData.chapterName = curNode.parentChapterName
          this.searchData.classHourId = curNode.id
          this.searchData.classHourName = curNode.name
          this.$store.commit('SET_INFO', this.searchData)
          this.defaultExpand.push(curNode)
          this.$refs.tree.setCurrentKey(curNode.key)
          this.getQuestionListByClassHourId(classHourId, this.materialInfo.materialId)
        } else {
          this.disabled = true
          this.questionList = []
        }
      })
    },
    // 点击tree
    handleLessonClick (data, e) {
      // isCurrent true为持续高亮  false为不高亮
      if (data.type !== 'LESSON') {
        e.isCurrent = false
        return
      }
      this.pageNumber = data.pageList ? data.pageList[0] : 1

      // e.isCurrent = true
      this.searchData.chapterId = data.chapter.id
      this.searchData.chapterName = data.chapter.name
      this.searchData.classHourId = data.id
      this.searchData.classHourName = data.name
      // this.$nStore.set('page_batchImport', this.searchData)
      this.$store.commit('SET_INFO', this.searchData)

      this.disabled = false
      this.questionList = []
      this.getQuestionListByClassHourId(data.id, this.materialInfo.materialId)
    },

    // 获取科目
    getSubjectList () {
      getSubjectList().then(res => {
        if (res.code === 0) {
          this.subjectList = res.data
          if (this.subjectList && this.subjectList.length) {
            const SUBJECT_ID = localStorage.getItem('SUBJECT_ID')
            if (SUBJECT_ID) {
              this.subjectValue = SUBJECT_ID
            } else {
              this.subjectValue = res.data[0].name
            }
            this.$store.commit('SET_SUBJECT', res.data[0].value)
          }
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).then(() => {
        this.getStandardTeachingMaterial(this.subjectValue)
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      })
    },
    // 获取版本年级
    getStandardTeachingMaterial (subject) {
      getStandardTeachingMaterial(subject).then(res => {
        if (res.code === 0) {
          const editionGradeList = res.data
          if (editionGradeList && editionGradeList.length) {
            editionGradeList.forEach(item => {
              item.id = item.sort
            })
            this.editionGradeList = editionGradeList
            const EG_ID = JSON.parse(localStorage.getItem('EG_ID'))
            if (EG_ID && EG_ID.length) {
              this.editionGradeValue = EG_ID
            }
          }
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).then(() => {
        const node = this.$refs.cascader.getCheckedNodes()
        if (node.length) {
          const eg = node[0].pathLabels[0] + node[0].pathLabels[1]
          this.$store.commit('SET_EDITION_GRADE', eg)
          if (this.editionGradeValue && this.editionGradeValue.length) {
            this.getTeachingMaterialChapter(this.editionGradeValue[1])
            this.getSubjectAndGrade(this.editionGradeValue[1])
            localStorage.setItem('STMID', this.editionGradeValue[1])
          }
        }
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      })
    },
    // 获取章节课时
    getTeachingMaterialChapter (standardTeachingMaterialId) {
      this.loadtree = true
      this.defaultExpand = []
      getTeachingMaterialChapter(standardTeachingMaterialId).then(res => {
        if (res.code === 0) {
          const CLASSHOURID = Number(localStorage.getItem('CLASSHOURID'))
          const treeData = res.data
          const curNode = this.formatTreeData(treeData, CLASSHOURID)
          this.treeData = treeData
          // 设置默认值 - 展开选中的节点
          this.$nextTick(() => {
            if (curNode) {
              this.defaultExpand.push(curNode)
              this.$refs.tree.setCurrentKey(curNode.key)
              const chapter = curNode.chapter
              if (chapter) {
                localStorage.setItem('CHAPTER', JSON.stringify({
                  id: chapter.id,
                  name: chapter.name
                }))
              }
              this.$store.commit('SET_LESSON', curNode.name)
              this.getQuestionListByClassHourId(CLASSHOURID, this.materialInfo.materialId)
              this.disabled = false
            }
          })
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).catch(err => {
        this.$message({ type: 'error', message: err })
      }).then(() => {
        this.$nextTick(() => {
          // const CLASSHOURID = Number(localStorage.getItem('CLASSHOURID'))
          // if (CLASSHOURID) {
          //   this.findTreeData(this.treeData, CLASSHOURID)
          //   this.$refs.tree.setCurrentKey(CLASSHOURID)
          //   this.getQuestionListByClassHourId(CLASSHOURID)
          // }
          // const node = this.$refs.tree.getCurrentNode()
          // console.log(node)
          // if (node) {
          //   this.$store.commit('SET_LESSON', node.name)
          //   this.disabled = false
          // }
        })
      }).finally(() => {
        this.loadtree = false
      })
    },
    /**
     * 格式化树 数据接口
     * @param list 章节课时树
     * @param id 默认节点Id
     * @param chapter 课时所属章节
     * 章节ID与课时ID都是自增字段，可能存在冲突 - 新增 key字段
     */
    formatTreeData (list, id, chapter) {
      let curNode = null
      list.forEach(item => {
        if (item.type === 'LESSON') {
          item.chapter = { id: chapter.id, name: chapter.name }
          item.key = chapter.id + '-' + item.id
          if (item.id === id) {
            // 默认选中节点
            curNode = item
          }
        } else {
          item.key = String(item.id)
          item.disabled = true
        }
        if (item.children && item.children.length) {
          const cur = this.formatTreeData(item.children, id, item.type === 'LESSON' ? chapter : item)
          cur && (curNode = cur)
        }
      })
      return curNode
    },
    // 查找默认节点所在的数组
    findTreeData (list, id, chapter) {
      list.forEach(item => {
        if (item.id === id) {
          return this.defaultExpand.push(item)
        }
        if (item.children && item.children.length > 0) {
          this.findTreeData(item.children, id)
        }
      })
      return this.defaultExpand
    },
    // 选择科目
    selectSubject () {
      this.editionGradeList = []
      this.editionGradeValue = []
      this.treeData = []
      this.disabled = true
      this.questionList = []
      this.uploadtip = '选择课时后即可导入'
      this.sqtip = '选择课时后即可识别'
      this.$store.commit('SET_EDITION_GRADE', '')
      this.$store.commit('SET_LESSON', '')
      ++this.cascaderKey
      this.getStandardTeachingMaterial(this.subjectValue)
      this.subjectList.forEach(item => {
        if (this.subjectValue === item.name) {
          this.$store.commit('SET_SUBJECT', item.value)
          localStorage.setItem('SUBJECT_ID', item.name)
        }
      })
    },
    // 选择版本/年级
    selectEditionGrade () {
      this.treeData = []
      this.disabled = true
      this.questionList = []
      this.uploadtip = '选择课时后即可导入'
      this.sqtip = '选择课时后即可识别'
      this.$store.commit('SET_LESSON', '')
      this.getTeachingMaterialChapter(this.editionGradeValue[1])
      this.getSubjectAndGrade(this.editionGradeValue[1])
      localStorage.setItem('STMID', this.editionGradeValue[1])
      localStorage.setItem('EG_ID', JSON.stringify(this.editionGradeValue))
      const node = this.$refs.cascader.getCheckedNodes()
      const eg = node[0].pathLabels[0] + node[0].pathLabels[1]
      this.$store.commit('SET_EDITION_GRADE', eg)
    },
    // 点击tree
    handleNodeClick (data, e) {
      // isCurrent true为持续高亮  false为不高亮
      if (data.type === 'LESSON') {
        const chapter = data.chapter
        if (chapter) {
          localStorage.setItem('CHAPTER', JSON.stringify({
            id: chapter.id,
            name: chapter.name
          }))
        }
        localStorage.setItem('CLASSHOURID', data.id)
        e.isCurrent = true
        this.$store.commit('SET_LESSON', data.name)
        this.disabled = false
        this.uploadtip = ''
        this.sqtip = ''
        this.questionList = []
        this.getQuestionListByClassHourId(data.id, this.materialInfo.materialId)
      } else {
        e.isCurrent = false
      }
    },
    // 开始上传
    getUploadProgress (list) {
      this.questionList = list
    },
    // 根据课时id获取所有题目
    getQuestionListByClassHourId (classHourId, materialId) {
      this.fullscreenLoading = true
      getQuestionListByClassHourId(classHourId, materialId).then(res => {
        if (res.code === 0) {
          this.questionList = res.data
        } else {
          this.$message({ type: 'error', message: res.msg })
        }
      }).finally(() => {
        this.fullscreenLoading = false
      })
    },
    getCommonSubsectionList () {
      return commonSubsectionList().then(res => {
        if (res.code === 0) {
          this.subsectionList = res.data.map(m => ({
            id: 's' + m.sort,
            subjectValue: m.subsection,
            children: m.subjects
          }))
          if (this.materialInfo.subsectionId) {
            this.getStandardMaterialList(this.materialInfo.subsectionId)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getStandardMaterialList (subsectionId) {
      return standardMaterialList(subsectionId).then(res => {
        if (res.code === 0) {
          const list = res.data || []
          list.forEach((m, index) => { m.id = 'e' + (index + 1) })
          this.editionList = list
          this.searchData.editionGradeValue = this.materialInfo.standardMaterialId
          this.searchData.editionName = this.findEditionData(this.editionList, this.materialInfo.standardMaterialId)[0] + this.findEditionData(this.editionList, this.materialInfo.standardMaterialId)[1]
          this.searchData.subjectId = this.materialInfo.subsectionId
          this.searchData.subjectName = this.findSubjectData(this.subsectionList, this.materialInfo.subsectionId)[1]
          this.searchData.teachingMaterialId = this.materialInfo.materialId
          // TODO: materialType
          this.searchData.materialType = this.materialInfo.type
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 查找科目名称
    findSubjectData (tree, id, path = []) {
      for (let i = 0; i < tree.length; i++) {
        const item = tree[i]
        if (item.id === id) {
          path.unshift(item.subjectValue)
          return path
        } else if (item.children && item.children.length) {
          this.findSubjectData(item.children, id, path)
          if (path.length) {
            path.unshift(item.subjectValue)
            return path
          }
        }
      }
    },
    // 查找教材名称
    findEditionData (tree, id, path = []) {
      for (let i = 0; i < tree.length; i++) {
        const item = tree[i]
        if (item.id === id) {
          path.unshift(item.name)
          return path
        } else if (item.list && item.list.length) {
          this.findEditionData(item.list, id, path)
          if (path.length) {
            path.unshift(item.name)
            return path
          }
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.bi-container{
  .bi-content{
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 10px 10px 0;
  }

  // 滚动条样式
  ::-webkit-scrollbar{
    width: 8px;
    height: 8px;
  }
  // 滚动条内部滑块样式
  ::-webkit-scrollbar-thumb{
    border-radius:10px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
    background-color:#F8F8F8;
  }
  .left{
    width: 280px;
    padding: 15px;
    background-color: #fff;
    margin-right: 10px;
    border-radius: 4px;

    .title{
      font-size: 15px;
      font-weight: 600;
      color: #000;
      margin-bottom: 30px;

      .subtitle {
        font-size: 12px;
        font-weight: normal;

        margin-top: 8px;
        color: #666;
      }
    }
    .treeBox{
      margin-left: -5px;
      height: calc(100vh - 374px);
      overflow: auto;
    }
    /deep/ .el-tree-node__content {
      height: auto;
      min-height: 26px;
    }
    /deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
      // 设置颜色
      background-color: #D0F3F3;
    }
  }
  .right{
    width: calc(100% - 350px);
    height: calc(100vh - 110px);
    padding: 15px;
    background-color: #fff;
    border-radius: 4px;
  }
  .uploadProgress{
    display: flex;
    align-items: center;
    justify-content: center;
    div{
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .tip{
        font-size: 15px;
        font-weight: 600;
        color: #000;
        margin: 20px;
      }
    }
  }
}

.treeItem {
  display: flex;
  flex-wrap: wrap;

  .label {
    margin-right: 1em;
  }
}
.num {
  color: #18C2C2;

  &::before {
    content: '( ';
  }
  &::after {
    content: ' )';
  }

  span {
    margin: 0 3px;

    &:nth-child(1) {
      color: #FA6400;
    }

    &:nth-child(2) {
      color: #1573BA;
    }
  }
}
.material-box{
  margin-bottom: 30px;
  .m-title{
    font-size: 15px;
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;
  }
  .material-info{
    display: flex;
    align-items: center;
    .right-content{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100px;
    }
    .one,.two{
      font-size: 13px;
      color: #999999;
    }
    .orange{
      color: #FA6400;
    }
    .blue{
      color: #1573BA;
    }
    .mar10{
      margin-right: 20px;
    }
    .three{
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #DBDBDB;
      width: 90px;
      height: 24px;
      border-radius: 12px;
      cursor: pointer;
      div{
        &:nth-child(1){
          width: 18px;
          height: 15px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        &:nth-child(2){
          font-size: 13px;
          color: #28B8BA;
          margin-left: 5px;
        }
      }
    }
  }
}
.mi{
  width: 80px;
  height: 100px;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 10px 5px 0;
  flex-shrink: 0;
  position: relative;
  .type-item{
    position: absolute;
    font-size: 20px;
    padding: 4px 6px;
    background-color: rgba(81, 155, 235, 0.5);
    top: 0;
    left: 0;
    color: #ffffff;
    border-radius: 4px;
    transform: scale(0.5);
    transform-origin: left top;
    max-width: 200%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &:hover{
    cursor: pointer;
  }
  .img{
    display: block;
    width: 100%;
    height: 100%;
  }
  .materialActive{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 100px;
    border-radius: 4px;
    background-color: rgba(40,184,186,0.2);
    z-index: 1;
    border: 2px solid rgba(40,184,186,0.9);
    .img2{
      display: block;
      width: 25px;
      height: 25px;
    }
  }
}
.selectMaterialBox{
  height: 166px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectMaterial{
  border: 1px solid #DBDBDB;
  border-radius: 15px;
  height: 30px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  div{
    &:nth-child(1){
      width: 18px;
      height: 15px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    &:nth-child(2){
      font-size: 13px;
      color: #28B8BA;
      margin-left: 5px;
    }
  }
}
</style>
